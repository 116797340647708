
import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
@Component

export default class DeletedLoan extends Vue {

public deletedLoans: any = []

public async getDeletedLoans() {
    
    // this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.get(
        `${BASE_API_URL}super-admin/deleted-loans/getDeletedLoans`,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.deletedLoans = response.data;
      }
    } catch (error) {
      console.log(error);
    }
    // this.$store.state.tmpcoLoader = false;
  }

   async mounted() {
    await this.getDeletedLoans();
  }

}

